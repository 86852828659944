<template>
  <div>
    <bread-crumb :crumbData="['产品档案', '编辑']" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm"
      style="padding: 20px; width: 96%">
      <el-form-item label="商品名称：" prop="product_name">
        <el-input v-model="ruleForm.product_name"></el-input>
      </el-form-item>
      <el-form-item label="卖点说明：">
        <el-input v-model="ruleForm.description"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="7">
          <el-form-item label="计量单位：" prop="unit" class="widthCol">
            <el-input v-model="ruleForm.unit" placeholder="只"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="商品分类：" prop="cid" class="widthCol">
            <el-select v-model="ruleForm.cid" clearable placeholder="请选择">
              <el-option v-for="item in cidDatas" :key="item.id" :value="item.id" :label="item.classify_name">
                {{ item.classify_name }}
                <div v-if="item.children">
                  <el-option v-for="item1 in item.children" :key="item1.id" :value="item1.id"
                    :label="item1.classify_name">
                    <span style="width-left: 10px">{{
                      item1.classify_name
                    }}</span>
                    <div v-if="item1.children">
                      <el-option v-for="item2 in item1.children" :key="item2.id" :value="item2.id"
                        :label="item2.classify_name">
                        <span style="margin-left: 20px">{{
                          item2.classify_name
                        }}</span>
                      </el-option>
                    </div>
                  </el-option>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="商品简称：" class="widthCol">
            <el-input v-model="ruleForm.nike_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="商品属性：" class="widthCol">
            <el-input v-model="ruleForm.attributes" placeholder="微甜"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="规格型号：" required> </el-form-item>
      <el-table :data="tableData" border style="width: 100%; margin-bottom: 20px; margin-top: -10px">
        <el-table-column label="货号/条码" width="120px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.number" style="width: 90%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="图片" width="100px">
          <template slot-scope="scope">
            <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="uploadImg">
              <img v-if="scope.row.thumbnail" :src="'http://192.168.3.3' + scope.row.thumbnail" class="avatar" />
              <el-button size="small" type="primary" class="uploadBtu">选择图片</el-button>
            </el-upload>
          </template>
        </el-table-column>
        <el-table-column label="规格">
          <template slot-scope="scope">
            <el-input v-model="scope.row.specifications" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <!-- 无法确认此用处 -->
        <el-table-column label="颜色" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.color" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="市场价" width="80px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.market_price" style="width: 80%" v></el-input>
          </template>
        </el-table-column>
        <el-table-column label="销售价" width="80px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sales_price" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="成本价" width="80px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.cost_price" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="库存" width="80px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.total_inventory" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="VIP1价" width="80px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.vip1" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="VIP2价" width="80px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.vip2" style="width: 70%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="VIP3价" width="80px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.vip3" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="VIP4价" width="80px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.vip4" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="VIP5价" width="80px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.vip5" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="月销量" width="80px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.monthly_sales" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column width="44">
          <template>
            <el-tooltip content="清空" placement="top">
              <el-button type="text" size="mini" @click="handleEmpty">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <span class="table_spriteChart_empty"></span>
                </div>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item label="品牌类型：">
        <el-select v-model="ruleForm.tid" clearable placeholder="请选择">
          <el-option v-for="item in brandTypes" :key="item.id" :label="item.column_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="商品类型：">
            <el-select v-model="ruleForm.product_type" clearable placeholder="请选择">
              <el-option v-for="(item, index) in product_types" :key="index" :label="item" :value="index"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="销售策略：">
            <el-select v-model="ruleForm.sales_strategy" clearable placeholder="请选择">
              <el-option v-for="item in sales_strategys" :key="item.id" :label="item.policy_name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="运费模版：">
            <el-select v-model="ruleForm.freight_template" clearable placeholder="请选择">
              <el-option v-for="item in freight_templates" :key="item.template_name" :label="item.template_name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="最小起订量：">
            <el-input v-model="ruleForm.minimum" placeholder="0"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="保质期(天)：">
            <el-input v-model="ruleForm.quality_guarantee" placeholder="0"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="重量(Kg)：">
            <el-input v-model="ruleForm.weight" placeholder="0"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="主供应商：">
        <el-select v-model="ruleForm.supplier" clearable placeholder="选择供应商">
          <el-option v-for="item in suppliers" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="商品标签：">
            <el-select v-model="ruleForm.product_label" multiple placeholder="请选择">
              <el-option v-for="item in product_labels" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item>
            <el-checkbox v-model="ruleForm.is_putaway" :true-label="1" :false-label="0">暂不上架</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item>
            <el-checkbox v-model="ruleForm.pledge_bucket" :true-label="1" :false-label="0">启用单品压桶</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="排序优先级：">
        <el-input v-model="ruleForm.sort"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="4">
          <el-form-item label="采购价：">
            <el-input v-model="ruleForm.purchase_price" placeholder="0"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="运费/件：" style="width: 150%">
            <el-input v-model="ruleForm.freight" placeholder="0"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="3">
          <el-form-item>
            <el-checkbox v-model="ruleForm.is_maitong" :true-label="1" :false-label="0">使用买桶券</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-checkbox v-model="ruleForm.is_sanlun" :true-label="1" :false-label="0">使用三轮券</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-checkbox v-model="ruleForm.is_nongfu" :true-label="1" :false-label="0">使用农夫代金券</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-checkbox v-model="ruleForm.is_highwater" :true-label="1" :false-label="0">是否高价水</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-button type="primary">一键同步</el-button>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-form-item label="厂区回桶品牌类型：">
        <el-select v-model="ruleForm.bucket_return" clearable placeholder="请选择">
          <el-option v-for="(item, index) in bucket_returns" :key="index" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["passPageId"],
  data() {
    return {
      ruleForm: {},
      rules: {
        product_name: [
          { required: true, message: "请填写内容", trigger: "blur" },
        ],
        unit: [{ required: true, message: "请填写内容", trigger: "blur" }],
        cid: [{ required: true, message: "请选择", trigger: "change" }],
        model: [
          {
            required: true,
            message: "请填写内容",
            trigger: "change",
          },
        ],
      },
      tableData: [],
      product_labels: [
        { value: "0", label: "推荐" },
        { value: "1", label: "新品" },
        { value: "2", label: "精选" },
      ],
      cidDatas: [],
      brandTypes: [],
      product_types: ["普通商品", "同城商品", "虚拟商品", "赠品"],
      sales_strategys: [],
      freight_templates: [],
      suppliers: [{ id: 0, label: "sw" }],
      bucket_returns: [],
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    axios
      .all([
        this.$http.post("/Goods_Category/lst", {
          commercial_id: this.commercial_id,
        }),
        this.$http.post("/Brand_Type/lst", {
          commercial_id: this.commercial_id,
        }),
        this.$http.post("/system/policy_set/lst", {
          commercial_id: this.commercial_id,
        }),
        this.$http.post("/system/freight_template/lst", {
          commercial_id: this.commercial_id,
        }),
      ])
      .then(
        axios.spread((res1, res2, res3, res4) => {
          this.cidDatas = res1.data.data;
          this.brandTypes = res2.data.data;
          this.sales_strategys = res3.data.data;
          this.freight_templates = res4.data.data;
        })
      );
    this.pageInfo();
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    // 上传文件
    // handleAvatarSuccess(index, response, file) {
    //   // this.thumbnail = response;
    //   this.$set(this.ruleForm.tableData[index], "thumbnail", response);
    //   this.$set(
    //     this.ruleForm.tableData[index],
    //     "imageUrl",
    //     URL.createObjectURL(file.raw)
    //   );
    // },
    async uploadImg(file) {
      try {
        const base64 = await this.getBase64(file.file);
        const image = await this.$http.post("uploads_images/up", {
          file: base64,
        });
        this.tableData[0].thumbnail = image.data.path;
      } catch (error) {
        console.log(error);
      }
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    // 页面数据
    pageInfo() {
      this.$http
        .post(
          "/product/detail",
          this.$qs.stringify({
            id: this.passPageId,
          })
        )
        .then((res) => {
          console.log(res);
          this.ruleForm = res.data;
          console.log(res.data)
          this.tableData.push(res.data.tableData);
          if (this.ruleForm.product_label != "") {
            this.ruleForm.product_label =
              this.ruleForm.product_label.split(",");
          } else {
            this.ruleForm.product_label = [];
          }
        });
    },

    // 表格清除
    handleEmpty() {
      this.tableData = [{}];
    },
    // 提交表单
    submitForm(formName) {
      this.tableData.forEach((item) => {
        delete item.imageUrl;
      });
      for (const i in this.tableData[0]) {
        this.ruleForm[i] = this.tableData[0][i];
      }
      if (
        Array.isArray(this.ruleForm.product_label) &&
        this.ruleForm.product_label.length != 0
      ) {
        this.ruleForm.product_label = this.ruleForm.product_label.join(",");
      }
      delete this.ruleForm.tableData;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.id = this.passPageId;
          this.$http.post("/product/add", this.ruleForm).then((res) => {
            this.$message({
              type: res.data.status,
              message: res.data.msg,
            });
            if (res.data.status == "success") {
              this.onClose(0);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar {
  width: 54px;
  height: 54px;
  display: block;
  // padding: 5px;
  margin: 0 auto;
  border: 1px solid #dddddd;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 10px !important;
}

.widthCol {
  width: 90%;
}

/deep/ .el-input__inner {
  padding: 0 5px;
}
</style>
